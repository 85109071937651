import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { createLogger } from '@/modules/logging/logger';
import animationData from '@/static/animations/diced.json';
import Lottie from 'react-lottie';
import { NewsletterModalView } from '@/lib/constants';
import axios from 'axios';
import FadeIn from 'react-fade-in';
import { validateEmail } from '@/lib/index';
import Link from 'next/link';
import SelectUSState from 'react-select-us-states';

const logger = createLogger({
  fileLabel: `components/shared/Newsletter`,
});
interface newsletterProps {
  afterOpenModal?: () => void;
  closeModal?: () => void;
  type: 'curator' | 'fan';
  confirmation: () => void;
}

export const Newsletter: React.FunctionComponent<newsletterProps> = ({
  closeModal,
  afterOpenModal,
  type,
  confirmation,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [currentNewsletterStep, setNewsletterStetp] = useState<NewsletterModalView>(NewsletterModalView.ENTER_EMAIL);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [instagram, setInstagram] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');

  const isReady = () => validateEmail(emailAddress);

  const sendEmail = async () => {
    if (!isReady) {
      return setEmailError(true);
    }
    setProcessing(true);
    await axios
      .post('/api/newsletter', {
        emailData: {
          email: emailAddress,
          instagram,
          type,
          city,
          state,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
    setProcessing(false);
    confirmation();
    closeModal();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      isOpen
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="absolute left-1/2 top-1/2 mr-0 w-[90%] -translate-y-1/2 -translate-x-1/2 transform overflow-y-auto rounded-sm border border-white bg-black p-6 text-white  sm:w-fit"
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-85"
      contentLabel="Example Modal">
      {currentNewsletterStep === NewsletterModalView.PROCESSING && (
        <FadeIn>
          <div className="pb4 dt center">
            <div className="v-mid dtc">
              <Lottie options={defaultOptions} height={200} width={200} />
            </div>
          </div>
        </FadeIn>
      )}
      {currentNewsletterStep === NewsletterModalView.ENTER_EMAIL && (
        <div>
          <form>
            <div className="mb-2 ">
              <h3 className="f4  mv1">Tap in</h3>
            </div>
            <form className="w-100 pt4 mw7 center">
              <div className=" w-100 mb2 pb2">
                <div className="mv3   ">
                  <small className=" db pb1">Email Address</small>

                  <input
                    value={emailAddress}
                    className="white ba-hover pa2 w-100 bg-transparent"
                    onChange={(e) => {
                      setEmailAddress(e.currentTarget.value);
                      validateEmail(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="mv3   ">
                  <small className=" db  pb1">Instagram Handle</small>

                  <input
                    value={instagram}
                    className="white ba-hover pa2 w-100 bg-transparent"
                    onChange={(e) => setInstagram(e.currentTarget.value)}
                  />
                </div>
                <div className="mv3   ">
                  <small className=" db  pb1">City</small>

                  <input
                    value={city}
                    className="white ba-hover pa2 w-100 bg-transparent"
                    onChange={(e) => setCity(e.currentTarget.value)}
                  />
                </div>
                <div className="mv3 ">
                  <small className=" db  pb1">State</small>

                  <SelectUSState
                    id="myId"
                    className="btn option-to-black white ba-hover pa2 w-100 bg-transparent"
                    onChange={(e) => setState(e)}
                  />
                </div>
                <p style={{ letterSpacing: '1px' }} className="tc mv3 pb1 center  ph1 text-xs">
                  BY SIGNING UP, YOU AGREE TO THE{' '}
                  <Link href="/terms" legacyBehavior>
                    <span className="b">TERMS OF USE</span>
                  </Link>{' '}
                  AND{' '}
                  <Link href="/privacy" legacyBehavior>
                    <span className="b">PRIVACY POLICY</span>
                  </Link>{' '}
                  AND TO RECEIVE ELECTRONIC COMMUNICATIONS FROM TBA.
                </p>
              </div>
            </form>
            <div className="dtc-l dtc-m v-mid tr f5-l f6 fw5 ">
              <button
                onClick={() => {
                  setProcessing(true);
                  sendEmail();
                }}
                disabled={processing}
                className="b--white dib white noselect dim br-100 b--solid pa2 mt2-l ph3 mr2 no-underline">
                {processing && <i className="fa fa-spinner fa-spin mr2" />}
                {processing ? 'Submitting...' : 'Submit'}
              </button>
              {!processing && (
                <span
                  onClick={() => {
                    setProcessing(false);
                    closeModal();
                  }}
                  className="b--white dib white noselect dim br-100 b--solid pa2 mr2 mt2-l ph3 mt2 no-underline">
                  Cancel
                </span>
              )}
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
