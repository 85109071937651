import * as React from 'react';
import { NextPage } from 'next';
import { Home } from '../src/components/Home';
import { Layout } from '../src/components/Layout/';
import { createLogger } from '@/modules/logging/logger';
import { EventProps } from '@/modules/data/types';
import { Db } from 'mongodb';
const connect = require('./api/db');
import { eventCollection } from './api/helpers';
import Sentry from '@/modules/sentry/init';

let logger = createLogger({
  fileLabel: '/pages/index',
});

interface HomeProps {
  eventsData?: EventProps[];
}

export async function getStaticProps() {
  try {
    const db: Db = await connect();
    const eventsData = await db
      .collection(eventCollection)
      .find({ eventType: 'public' })
      .sort({ startDate: -1 })
      .limit(30)
      .toArray();
    return {
      props: {
        eventsData: JSON.parse(JSON.stringify(eventsData)),
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 10, // In seconds
    };
  } catch (e) {
    logger.error(e);
    Sentry.captureException(e);
  }
}

const HomePage: NextPage<HomeProps> = ({ eventsData }) => {
  const site = {
    title: `TBA`,
    header: 'TBA',
  };

  return (
    <Layout data={site}>
      <Home events={eventsData} />
    </Layout>
  );
};

export default HomePage;
