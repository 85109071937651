import { EventProps } from '@/modules/data/types';
import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import moment from 'moment';
import { formatDateTimeWithTimeZone, hasPublicEventsInFuture, hasPublicEventsInPast } from '../lib';
import { createLogger } from '@/modules/logging/logger';
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Newsletter } from './shared/Newsletter';
import Reward from 'react-rewards';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
const logger = createLogger({
  fileLabel: `components/Home`,
});
interface HomeProps {
  events: EventProps[];
}
export const Home: React.FunctionComponent<HomeProps> = ({ events }) => {
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [type, setType] = useState<'curator' | 'fan'>(null);
  const reward = useRef(null);

  useEffect(() => {
    if (emailSent) {
      reward.current.rewardMe();
      setEmailSent(false);
    }
  }, [emailSent]);
  const sendEmail = async () => {
    await axios
      .post('/api/sailthru', {
        emailData: {
          email: emailAddress,
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
    setProcessing(false);
    setEmailSent(true);
  };

  const hasEventsinPast = events?.some((e) => hasPublicEventsInPast(e));

  const hasEventsinFuture = events?.some((e) => hasPublicEventsInFuture(e));

  return (
    <div className={'pv4 mw8 relative ml4-md center'}>
      <div className="mw8 ">
        {/* <div
        className="relative db overflow-hidden"
        style={{ zIndex: 10, top: 0, left: 0 }}
      >
        <video autoPlay loop className="sc-1fs7h0j-7 w-60">
          <source
            data-src="https://dice-media.imgix.net/videos/homepagecover.3b7f329175c1ef6acc9454ae7eb83ac0.mp4"
            data-mobsrc="https://dice-media.imgix.net/videos/homepagecover-mobile.b7dd4a81a84fc704231f356251e7b5f0.mp4"
            src="https://dice-media.imgix.net/videos/homepagecover.3b7f329175c1ef6acc9454ae7eb83ac0.mp4"
          />
        </video>
      </div> */}

        <h1 className="f2-ns f3 mb2 font-bold font-grotesk-md ">
          Think of A&R but for events. TBA is an event ticketing & cultural platform that creates experiences to
          discover what’s next through safe and inclusive spaces.
        </h1>
        <img className="pv2 mv2" src="/static/images/tba-strip-basic.png" />

        <h1 className="f2-ns f3 font-bold font-grotesk-md ">
          We partner with state-of-the-art venues, buzzing artists, and event producers that aren’t afraid to experiment
          and push boundaries.
        </h1>
        <div className="tc z-20">
          <Reward
            ref={(ref) => {
              reward.current = ref;
            }}
            config={{ spread: 150, lifetime: 400, elementCount: 350 }}
            type="confetti"
          />
        </div>
        <div
          style={{
            background:
              'linear-gradient(45deg, rgba(212,70,41,1) 0%, rgba(106,94,87,0.9830508474576272) 29%, rgba(77,117,140,1) 65%, rgba(105,165,67,1) 95%)',
          }}
          className="mt4 pv4 mb2 text-center">
          <h3 className="f3-ns ml-3 font-bold f4 pb-2 mb-1 f4 uppercase text-center noselect">
            Sign up for early access
          </h3>
          <div className="flex justify-center">
            <button
              onClick={() => {
                setType('curator');
                setOpenForm(true);
                trackAction({
                  event: AMPLITUDE_EVENTS.HOME_CLICK,
                  action: AMPLITUDE_ACTIONS.CLICK,
                  opts: { button: 'curator' },
                });
              }}
              className="ba bw2 b--black no-underline dib noselect  br-100 b--solid bg-white hover-bg-black hover-white text-black transition-all  pa2 mb2 ph3 f4 fw5 mr-2 md:mr-4">
              For Curators
            </button>
            <button
              onClick={() => {
                setType('fan');
                setOpenForm(true);
                trackAction({
                  event: AMPLITUDE_EVENTS.HOME_CLICK,
                  action: AMPLITUDE_ACTIONS.CLICK,
                  opts: { button: 'fan' },
                });
              }}
              className="ba bw2 b--black no-underline dib noselect  br-100 b--solid bg-white hover-bg-black hover-white text-black transition-all  pa2 mb2 ph3 f4 fw5 ml-2 md:ml-4">
              For Fans
            </button>
          </div>
        </div>
      </div>
      {openForm && (
        <Newsletter confirmation={() => setEmailSent(true)} closeModal={() => setOpenForm(false)} type={type} />
      )}
      {hasEventsinFuture && (
        <>
          <div className="w-max ba bw2 br-100  mv4 pv2 ph3 f2-ns f3 fw5 ">Upcoming Events</div>
          <div className="">
            <div className=" grid sm:grid-cols-2 grid-cols-1 gap-4 my-8  ">
              {events
                .filter((event) => hasPublicEventsInFuture(event))
                .sort((a, b) =>
                  moment(a.startDate).isSame(b.startDate) ? 0 : moment(a.startDate).isBefore(b.startDate) ? -1 : 1,
                )
                .map((event, key) => {
                  const {
                    slug,
                    image,
                    name,
                    url,
                    imageWide,
                    startDate,
                    location: { city, state, timeZoneId },
                  } = event;

                  const urlWork = url ? `http://${url}?utm_source=whatstba.com&utm_medium=home_page` : `/e/${slug}`;
                  return (
                    <div key={key} className={`justify-between h-full flex flex-col`}>
                      <div className="w-100 ">
                        <a className="transition " href={urlWork}>
                          <div
                            className="w-full h-auto relative flex md:min-h-[282px] min-h-[120px] "
                            style={{ boxSizing: 'inherit' }}>
                            <img
                              className=" w-full h-auto sm:object-cover sm:object-top sm:h-[282px] "
                              src={imageWide || image}
                            />
                          </div>
                        </a>
                      </div>
                      <Link href={urlWork} legacyBehavior>
                        <span className="font-semibold text-2xl mt-2 db">{name}</span>
                      </Link>
                      <div className="flex gap-2 items-center my-2">
                        <Link href={urlWork} legacyBehavior>
                          <span className="font-medium border bg-zinc-200 text-black border-white rounded-full px-4 py-1">
                            {`${event.location.url ? `Online` : `${city}, ${state}`}`}
                          </span>
                        </Link>

                        <Link href={urlWork} legacyBehavior>
                          <span className="border border-white rounded-full px-4 py-1 text-white">
                            {formatDateTimeWithTimeZone(new Date(startDate), 'home', timeZoneId)}
                          </span>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}

      {hasEventsinPast && !hasEventsinFuture && (
        <>
          <div className="w-max ba bw2 br-100  mv4 pv2 ph3 f2-ns f3 fw5 ">Past Events</div>

          <div>
            <div className=" grid sm:grid-cols-2 grid-cols-1 gap-4 my-8  ">
              {events
                .filter((event) => hasPublicEventsInPast(event))
                .map((event, key) => {
                  const {
                    slug,
                    image,
                    url,
                    name,
                    imageWide,
                    location: { city, state },
                  } = event;
                  const urlWork = url ? `http://${url}?utm_source=whatstba.com&utm_medium=home_page` : `/e/${slug}`;

                  return (
                    <div key={key} className={`justify-between h-full flex flex-col`}>
                      <div className="w-100">
                        <a className="transition" href={urlWork}>
                          <Image
                            objectFit="cover"
                            objectPosition="top"
                            src={imageWide}
                            width={2160}
                            height={1080}
                            alt={name}
                          />
                        </a>
                      </div>
                      <Link
                        href={{
                          pathname: urlWork,
                        }}
                        className="fw5 f3 mt2 db">
                        {name}
                      </Link>
                      <Link href={urlWork} className="fw5 db gray f4 mt1">
                        {`${event.location.url ? `Online` : `${city}, ${state}`}`}
                      </Link>
                    </div>
                  );
                })
                .reverse()
                .slice(0, 4)}
            </div>
          </div>
        </>
      )}
      <div className="bb-hover fw3 f3 pv3">
        <Link legacyBehavior href="/events">
          <a className="pv2 b pa2 dim white no-underline">See More Events</a>
        </Link>
      </div>
    </div>
  );
};
